import Vue from 'vue'
import App from './App.vue'
import Less from 'less'
import VueClipboard from 'vue-clipboard2'
require('./rem.js')

Vue.use(Less)
Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  render: function(h) {
    return h(App)
  }
}).$mount('#app')
