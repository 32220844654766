<template>
  <div
    id="ocrPanel"
    :style="containerHeight"
  >
    <div class="title">
      <p>识别结果</p>
    </div>
    <div
      v-if="!showContent"
      class="content_error"
    >
      <p>{{ content_error }}</p>
    </div>
    <textarea
      v-if="showContent"
      v-model="content"
      class="content"
    />
    <div class="button_panel">
      <van-button
        class="item"
        round
        block
        type="info"
        @click.stop="closeOcr"
      >
        返回首页
      </van-button>
      <van-button
        class="item"
        round
        block
        type="primary"
        @click.stop="copyContent"
      >
        一键复制
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, Toast } from 'vant'
import axios from 'axios'
export default {
  name: 'OcrPanel',
  components: {
    [Button.name]: Button
  },
  props: {
    imgObj: {
      type: Object, // 指定传入的类型
      default: function () {
        return {
          file: null,
          temp: null
        }
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      content_error: '暂无识别结果',
      content: '',
      ocrApi: '//api.91porn.xiaoyuyuan.cn/?service=NineOnePorn.NineOnePorn.ocr'
    }
  },
  computed: {
    showContent: function () {
      if (this.content.length > 0) {
        return true
      }
      return false
    },
    containerHeight: function () {
      let h = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        if (
          document.body.clientHeight < document.documentElement.clientHeight
        ) {
          h = document.body.clientHeight
        } else {
          h = document.documentElement.clientHeight
        }
      } else {
        if (
          document.body.clientHeight > document.documentElement.clientHeight
        ) {
          h = document.body.clientHeight
        } else {
          h = document.documentElement.clientHeight
        }
      }
      return { height: h + 'px' }
    }
  },
  mounted() {
    this.ocr()
  },
  methods: {
    // 发起OCR识别
    ocr() {
      if (!this.imgObj || !this.imgObj.file) {
        Toast('没有图片识别')
        return false
      }
      Toast.loading({
        message: '识别中...',
        duration: 0,
        forbidClick: true
      })
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      let _this = this
      var formData = new FormData()
      formData.append('img', this.imgObj.file)
      // 请求API接口
      axios
        .post(this.ocrApi, formData, config)
        .then(function (response) {
          if (
            response.data &&
            response.data.data &&
            response.data.data.content
          ) {
            _this.content = response.data.data.content
            Toast.success('识别完成')
            return true
          }
          Toast.clear()
        })
        .catch(function (error) {
          Toast({
            duration: 2500, // 持续展示 toast
            message: 'API请求失败',
            icon: 'cross'
          })
          console.log(error)
        })
    },
    // 关闭本页
    closeOcr() {
      this.$emit('closeOcr')
    },
    copyContent() {
      if (!this.content.length) {
        Toast('没有识别结果')
        return false
      }
      this.$copyText(this.content).then(
        function () {
          Toast('复制成功')
        },
        function (e) {
          Toast('浏览器不支持')
          console.log(e)
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#ocrPanel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    p {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  .content_error {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    height: 100%;
    padding: 10px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    resize: none;
    /* 解决自动换行问题 */
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }
  .button_panel {
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 10px 0;
    .item {
      width: 36%;
    }
  }
}
</style>
