<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from './components/Index.vue'

export default {
  name: 'App',
  components: {
    Index
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
