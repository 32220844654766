<template>
  <div
    id="container"
    :style="containerHeight"
  >
    <div
      v-if="showIndex"
      id="button_panel"
    >
      <div id="title_outter">
        <p id="title">
          {{ pageTitle.main }}
        </p>
        <p id="title_second">
          {{ pageTitle.extra }}
        </p>
      </div>
      <ButtonPanel @openPicCut="openPicCut" />
    </div>
    <div
      v-if="showCut"
      id="cut_panel"
    >
      <CutPanel
        :img-obj="imgObj"
        @closePicCut="openIndex"
        @changeImgObj="ocr"
      />
    </div>
    <div
      v-if="showOcr"
      id="ocr_panel"
    >
      <OcrPanel
        :img-obj="imgObj"
        @closeOcr="openIndex"
      />
    </div>
  </div>
</template>

<script>
import ButtonPanel from './ButtonPanel'
import CutPanel from './CutPanel'
import OcrPanel from './OcrPanel'

export default {
  name: 'Index',
  components: {
    ButtonPanel,
    CutPanel,
    OcrPanel
  },
  data() {
    return {
      pageTitle: {
        main: 'OCR文字识别',
        extra: '(讯飞手机输入法API)'
      },
      show: {
        buttonPanel: true,
        cutPanel: false,
        ocrPanel: false
      },
      imgObj: null
    }
  },
  computed: {
    showIndex: function () {
      if (this.show.buttonPanel && !this.show.cutPanel && !this.show.ocrPanel) {
        return true
      }
      return false
    },
    showCut: function () {
      if (!this.show.buttonPanel && this.show.cutPanel && !this.show.ocrPanel) {
        return true
      }
      return false
    },
    showOcr: function () {
      if (!this.show.buttonPanel && !this.show.cutPanel && this.show.ocrPanel) {
        return true
      }
      return false
    },
    containerHeight: function () {
      let h = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        if (
          document.body.clientHeight < document.documentElement.clientHeight
        ) {
          h = document.body.clientHeight
        } else {
          h = document.documentElement.clientHeight
        }
      } else {
        if (
          document.body.clientHeight > document.documentElement.clientHeight
        ) {
          h = document.body.clientHeight
        } else {
          h = document.documentElement.clientHeight
        }
      }
      return { height: h + 'px' }
    }
  },
  created() { },
  mounted() { },
  methods: {
    // 打开切图工具
    openPicCut(imgObj) {
      this.imgObj = imgObj
      this.show.cutPanel = true
      this.show.buttonPanel = false
      this.show.ocrPanel = false
    },
    // 打开首页
    openIndex() {
      this.imgObj = null
      this.show.cutPanel = false
      this.show.buttonPanel = true
      this.show.ocrPanel = false
    },
    // 打开OCR识别结果页面
    openOcr(imgObj) {
      this.imgObj = imgObj
      this.show.cutPanel = false
      this.show.buttonPanel = false
      this.show.ocrPanel = true
    },
    // 提交ocr识别
    ocr(imgObj) {
      this.openOcr(imgObj)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#container {
  #button_panel {
    #title_outter {
      p {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        &#title {
          font-weight: bold;
          padding: 10px 0;
          font-size: 50px;
        }
        &#title_second {
          padding: 10px 0;
          font-size: 25px;
        }
      }
      margin-bottom: 50px;
    }
  }
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  position: relative;
}
</style>
