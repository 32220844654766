<template>
  <div id="cutPanel">
    <div class="content">
      <vue-picture-cut
        ref="pictureCut"
        :src="imgObj.temp"
        :magnification="cutCfg.magnification"
        :encoder-options="cutCfg.encoderOptions"
        :format="cutCfg.format"
        :menu-thickness="cutCfg.menuThickness"
      >
        <vue-picture-cut-mask
          :width="imgObj.width"
          :height="imgObj.height"
          :resize="cutCfg.mask_resize"
        />
      </vue-picture-cut>
    </div>
    <div class="panel">
      <div class="change_angle">
        <div class="slider">
          <van-slider
            v-model="slider.initAngle"
            :max="slider.maxAngle"
            @input="changeAngle"
          />
        </div>
      </div>
      <div
        ref="button_panel"
        class="button_panel"
      >
        <van-button
          class="item"
          round
          block
          type="info"
          @click.stop="closePicCut"
        >
          返回
        </van-button>
        <van-button
          class="item"
          round
          block
          type="primary"
          @click.stop="setPicCut"
        >
          一键识别
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Slider } from 'vant'
import { VuePictureCut, VuePictureCutMask, createUtils } from 'vue-picture-cut'
export default {
  name: 'CutPanel',
  components: {
    [Button.name]: Button,
    [Slider.name]: Slider,
    VuePictureCut,
    VuePictureCutMask
  },
  props: {
    imgObj: {
      type: Object, // 指定传入的类型
      default: function () {
        return {
          file: null,
          temp: null,
          width: 0,
          height: 0
        }
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      cutCfg: {
        magnification: 1,
        encoderOptions: 1,
        format: 'image/png',
        menuThickness: 0,
        mask_width: 2,
        mask_height: 1,
        mask_resize: true
      },
      slider: {
        maxAngle: 360,
        initAngle: 0
      },
      utils: null
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.initCutPanel()
  },
  methods: {
    // 初始化剪贴板
    initCutPanel() {
      if (this.imgObj.temp !== null) {
        this.utils = createUtils(this.$refs.pictureCut)
        this.cutCfg.mask_height = this.utils.photoRoot.drawHeight
        this.cutCfg.mask_width = this.utils.photoRoot.drawWidth
      }
    },
    // 关闭裁剪工具
    closePicCut() {
      this.$emit('closePicCut')
    },
    // 改变图片角度
    changeAngle(angle) {
      this.utils.rotateTo(angle, true)
    },
    // 图片裁剪变化触发函数
    setPicCut() {
      const opt = {
        encoderOptions: this.cutCfg.encoderOptions,
        format: this.cutCfg.format
      }
      const res = this.utils.cut(opt)
      if (res) {
        let imgObj = {
          file: this.dataURLtoFile(res.src),
          temp: res.src
        }
        this.$emit('changeImgObj', imgObj)
      }
    },
    // base64图像转File对象
    dataURLtoFile(dataURL) {
      let arr = dataURL.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let fileName = mime.replace('/', '.')
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime }) // 返回file对象
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import '~vue-picture-cut/lib/vue-picture-cut.css';
#cutPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .content {
    width: 100%;
    height: 100%;
  }
  .panel {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
  }
  .change_angle {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 0.5rem;
    .slider {
      width: 85%;
    }
  }
  .button_panel {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding-bottom: 10px;
    .item {
      width: 36%;
    }
  }
}
</style>
