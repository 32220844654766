<template>
  <div id="buttonPanel">
    <van-uploader :after-read="loadImageInUploader">
      <van-button
        class="item"
        round
        block
        type="primary"
      >
        {{ buttonTitle.upload }}
      </van-button>
    </van-uploader>
    <van-button
      v-show="checkNavigatorClipboardSupport"
      class="item"
      round
      block
      type="info"
      @click.stop="loadImageInClipboard"
    >
      {{ buttonTitle.clipboard }}
    </van-button>
  </div>
</template>

<script>
import { Button, Uploader, Toast } from "vant"
import VueClipboard from "vue-clipboard2"

export default {
  name: "ButtonPanel",
  components: {
    [VueClipboard.name]: VueClipboard,
    [Button.name]: Button,
    [Uploader.name]: Uploader
  },
  data() {
    return {
      buttonTitle: {
        clipboard: "读取剪贴板",
        upload: "上传图片"
      },
      bind_paste: null
    }
  },
  computed: {
    checkNavigatorClipboardSupport() {
      const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
        navigator.userAgent
      )
      if (
        !isMobile &&
        navigator.clipboard !== undefined &&
        navigator.clipboard.read !== undefined
      ) {
        return true
      }
      return false
    }
  },
  created() {
    this.listenPaste()
  },
  beforeDestroy() {
    document.removeEventListener("paste", this.bind_paste)
  },
  methods: {
    // 注册监听全局粘贴事件
    listenPaste() {
      const _this = this
      this.bind_paste = function (e) {
        _this.loadImageInClipboard(e)
      }
      document.addEventListener("paste", this.bind_paste)
    },
    // 从上传文件按钮读取文件
    async loadImageInUploader(file) {
      if (file) {
        const imgObj = {
          file: file.file,
          temp: URL.createObjectURL(file.file)
        }
        await this.openPicCut(imgObj)
        Toast("读取成功")
      } else {
        Toast("请先上传图片")
      }
    },
    // 从剪贴板读取文件
    async loadImageInClipboard(e) {
      let file = null
      if (
        navigator.clipboard !== undefined &&
        navigator.clipboard.read !== undefined
      ) {
        file = await this.getPasteInNavigatorClipboard()
      } else {
        file = this.getPasteInKeyboard(e)
      }

      if (file) {
        const imgObj = {
          file: file,
          temp: URL.createObjectURL(file)
        }
        await this.openPicCut(imgObj)
        Toast("读取成功")
      } else {
        Toast("请先复制图片")
      }
    },
    // 从Navigator对象获取剪贴板图片资源
    async getPasteInNavigatorClipboard() {
      const clipboardItems = await navigator.clipboard.read()
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          if (type.match(/image\/.*/)) {
            const fileName = type.replace("/", ".")
            const blob = await clipboardItem.getType(type)
            const file = new window.File([blob], fileName, {
              type: type
            })
            return file
          }
        }
      }
      return false
    },
    // 从粘贴操作返回对象获取剪贴板图片资源
    getPasteInKeyboard(e) {
      const cbd = e.clipboardData
      const ua = window.navigator.userAgent

      // 如果是 Safari 直接 return
      if (!(e.clipboardData && e.clipboardData.items)) {
        return false
      }

      // Mac平台下Chrome49版本以下 复制Finder中的文件的Bug Hack掉
      if (
        cbd.items &&
        cbd.items.length === 2 &&
        cbd.items[0].kind === "string" &&
        cbd.items[1].kind === "file" &&
        cbd.types &&
        cbd.types.length === 2 &&
        cbd.types[0] === "text/plain" &&
        cbd.types[1] === "Files" &&
        ua.match(/Macintosh/i) &&
        Number(ua.match(/Chrome\/(\d{2})/i)[1]) < 49
      ) {
        return false
      }
      for (var i = 0; i < cbd.items.length; i++) {
        const item = cbd.items[i]
        if (item.kind === "file") {
          const blob = item.getAsFile()
          if (blob.size === 0) {
            return false
          }
          return blob
        }
      }
      return false
    },
    // 读取图片宽高
    getImageSize(fileData) {
      return new Promise((resolve) => {
        const img = new Image()
        img.onload = function () {
          resolve({
            width: this.width,
            height: this.height
          })
        }
        img.src = fileData
      })
    },
    // 通知父组件打开切图工具
    async openPicCut(imgObj) {
      const imgSize = await this.getImageSize(imgObj.temp)
      Object.assign(imgObj, imgSize)
      this.$emit("openPicCut", imgObj)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#container {
  #buttonPanel {
    box-sizing: border-box;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .item {
      box-sizing: border-box;
      width: 200px;
      font-size: 24px;
      padding: 20px 0;

      &::-moz-focus-inner {
        border: none;
      }
    }
  }
}
</style>
